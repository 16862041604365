import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    Button,
    message,
    Form,
    Input,
    InputNumber,
    Select,
    Row,
    Col,
    Anchor,
    Card,
    DatePicker,
    Upload,
    Modal,
} from "antd";
import { getAuftrag, getToken } from "../helpers";
import {
    downloadRenderFile, getAPIurl,
    getInitialValuesFormular,
    getKontakte, handleCreateKontakt,
    LoadingScreen,
    MyInputNumber,
    openS3File,
    optimizedHandleScroll,
    postBewertungsobjekt,
    postGutachten,
    SaveIcon,
    setInitialUploadForms,
    updateDataContent,
    uploadFile,
} from "../helpers/formHelpers";
import {
    ArrowLeftOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    ExportOutlined,
    PlusOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { AutoComplete } from "antd";
import useScreenSize from "../hooks/useScreenSize";
import { RiDeleteBin5Fill } from "react-icons/ri";
import '../Styles/Kontakte.css'
import { useSwitch } from '../pages/SwitchContext';
import { RxCross2 } from "react-icons/rx";
import { HashLink as Linki } from "react-router-hash-link";
import {useAuthContext} from "../context/AuthContext";
const { Option } = Select;
const { Link } = Anchor;


let navigate;

let dataContainer;
let dataContent;
let enumerationValuesContent;
let updateDataContentValue;
let updateDataContentContainer;
let kontakteValues;
let kontakteValuesOptions;
let SachverstaendigerValuesOptions;

let currentFileChanged;
let currentFileChangedID;
let currentFileChangedComponent;

const putBody = {};

const AuftragDetails = () => {

    const [vis, setVis] = useState(false);
    const { isDesktopView } = useScreenSize();
    navigate = useNavigate();
    const { isSwitchOn, toggleSwitch } = useSwitch();
    useEffect(() => {
        if (isDesktopView) {
            setVis(true);
        } else {
            setVis(false);
        }
    }, [isDesktopView]);
    const handleVisibilityChange = (newVisibility) => {
        setVis(newVisibility);
    };

    return (
        <>
            <div className="w-full">
                <div
                    className={`${!isDesktopView ? "visible cursor-pointer" : "hidden"
                        } text-white pl-[15px]`}
                    onClick={() => {
                        setVis(!vis);
                    }}
                >
                    <button className="menuBtn">Menu</button>
                </div>
                <div
                    className={`flex flex-row gap-[30px] relative mr-[15px]  mb-[40px] ${!isDesktopView ? "ml-[15px]" : ""
                        }`}
                >
                    {vis ? (
                        <div className="w-[299px] absolute lg:relative z-10 lowerHeight lg:h-auto  ">
                            <MenuContainer
                                vis={vis}
                                onVisibilityChange={handleVisibilityChange} />
                        </div>
                    ) : (
                        ""
                    )}
                    <div className={`w-full ${isSwitchOn ? 'bg-black' : 'bg-white'} rounded-[30px]`}>
                        <FormContainer />
                    </div>
                </div>
            </div>
        </>
    );
};
const MenuContainer = ({ vis, onVisibilityChange }) => {
    window.addEventListener('scroll', optimizedHandleScroll);
    const { isSwitchOn, toggleSwitch } = useSwitch();
    const [isInLower20, setIsInLower20] = useState(false);
    const handleBackButtonClick = () => {
        navigate("/auftrag")
    }
    const handleClick = () => {
        const newVisibility = !vis;
        onVisibilityChange(newVisibility);
    };
    useEffect(() => {

        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;

            // Check if the device height is less than 600px
            const isDeviceHeightBelow600 = window.innerHeight < 600;

            // Calculate the scroll position percentage
            const scrollPercentage = (scrollPosition / (documentHeight - windowHeight)) * 100;

            // Set the state based on the scroll position and device height
            setIsInLower20(isDeviceHeightBelow600 && scrollPercentage >= 80);
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className={`sidebar rounded-r-[30px] ${isSwitchOn ? 'bg-black' : 'bg-white'}`}>
            <div className={`ml-[197px]`}><RxCross2 onClick={handleClick} className={`lg:hidden ${isSwitchOn ? 'text-white' : 'text-blaxk'} text-[30px] mb-[20px] `} /></div>
            {/* <Link to={"/auftrag"}> */}
            <Button
                type="primary"
                className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'} border-none ml-[20px] rounded-full buttonHover1`}
                // href={"/auftrag"}
                onClick={handleBackButtonClick}
                icon={<ArrowLeftOutlined />} >
                Zurück zur Übersicht
            </Button>
            {/* </Link> */}
            <Anchor affix={false} className={`your-component ${isInLower20 ? 'lower-20' : ''}`}>
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#allgemein" title="Allgemein" />
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#sachverstaendiger" title="Sachverständiger" />
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#kontaktliste" title="Kontaktliste" />
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#angebot" title="Angebot" />
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#rechnungen" title="Rechnungen" />
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#leistungen" title="Leistungen" />
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#un-auftrag" title="Auftragsunterlagen" />
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#links" title="Links" />
            </Anchor>
        </div>
    );
};

const FormContainer = () => {
    const location = useLocation()
    const [loading, setIsLoading] = useState(false);
    const [saveIcon, setSaveIcon] = useState(false);
    const [saveIcon1, setSaveIcon1] = useState(false);
    const { auftragID } = useParams(); // Get the activeKey from the URL
    const [formFormular] = Form.useForm();
    const [selectedOption, setSelectedOption] = useState(null);
    const [uploadForms, setUploadForms] = useState([]);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const updatedUploadForms = [...uploadForms];
    const [cards, setCards] = useState([]);
    const [selectedKontaktIDs, setSelectedKontaktIDs] = useState({});
    const [selectedSachverstaendigerIDs, setSelectedSachverstaendigerIDs] = useState({});
    const [initialValues, setInitialValuesState] = useState([]);
    const { isSwitchOn, toggleSwitch } = useSwitch();
    const { user, setUser } = useAuthContext();

    navigate = useNavigate();

    const handleSelectChange = (value, entryID,type="") => {
        setSelectedOption(value);

        if(type == "kontakt"){
            setSelectedKontaktIDs((prevSelected) => ({
                ...prevSelected,
                [entryID]: value,
            }));
        } else if(type == "sachverstaendiger"){
            setSelectedSachverstaendigerIDs((prevSelected) => ({
                ...prevSelected,
                [entryID]: value,
            }));
        }
    };

    // const fullName = SachverstaendigerValues[selectedSachverstaendigerIDs[entry.id]].Kontaktname;
    // const words = fullName.split(' ');
    // const initials = words.length >= 2 ? `${words[0][0]}${words[1][0]}` : '';
    // Sende einen POST-Request an deine Strapi-API, um den neues Bewertungsobjekt zu erstellen => Navigate to new object
    const addNewGutachten = async () => {
        try {
            const responseGutachtenData = await postGutachten(auftragID);
            const newUrl = `/gutachten/${auftragID}/${responseGutachtenData.id}`;
            navigate(newUrl);
        } catch (error) {
            // Hier können Sie Fehler behandeln, wenn die Anfrage fehlschlägt
            console.error("Fehler beim Senden der POST-Anfrage:", error);
        }
    };

    const fetchAuftrag = async (jumptoanchor=true) => {
        setIsLoading(true);

        try {
            if(user) {
                const response = await fetch(
                    `${getAPIurl()}/helper/acl/auftrag/${auftragID}?populate=deep,3`,
                    {
                        // &filters[role][$eq]=${userRole}
                        headers: {
                            // set the auth token to the user's jwt
                            Authorization: `Bearer ${getToken()}`,
                        },
                    }
                );
                dataContainer = await response.json();
                dataContent = dataContainer;
                setCards({...dataContent});
                // Assign initial values for contacts & Sachverständiger data preview
                const initialSelectedKontakte = {};
                if (!user.extern) {
                    dataContent.Kontaktliste.forEach((entry) => {
                        initialSelectedKontakte[entry.id] = entry.kontaktID || null;
                    });
                    setSelectedKontaktIDs(initialSelectedKontakte);
                    const initialSelectedSachverstaendige = {};
                    dataContent.Sachverstaendiger.forEach((entry) => {
                        initialSelectedSachverstaendige[entry.id] =
                            entry.SachverstaendigerID || null;
                    });
                    setSelectedSachverstaendigerIDs(initialSelectedSachverstaendige);
                }

                //Get enumerationValues
                const enumerationValues = await fetch(
                    `${getAPIurl()}/auftrag/enumvalues`,
                    {
                        // &filters[role][$eq]=${userRole}
                        headers: {
                            // set the auth token to the user's jwt
                            Authorization: `Bearer ${getToken()}`,
                        },
                    }
                );
                enumerationValuesContent = await enumerationValues.json();
                //Load files
                setUploadForms(
                    setInitialUploadForms(
                        updatedUploadForms,
                        dataContent.UN_Auftrag.Auftrag_Doc,
                        "Auftrag_Doc",
                        true
                    )
                );
                setUploadForms(
                    setInitialUploadForms(
                        updatedUploadForms,
                        dataContent.UN_Auftrag.Vollmacht_Doc,
                        "Vollmacht_Doc",
                        true
                    )
                );

                //Get Kontakte for enumerationValues
                if (!user.extern) {
                    kontakteValues = await getKontakte();
                    kontakteValuesOptions = await getKontakte(true);

                    // Get Sachverständige (User)
                    const sachverstaendigerUser = await fetch(
                        `${getAPIurl()}/helper/acl/users?filters[$and][0][sachverstaendiger][$eq]=true&filters[$and][1][blocked][$eq]=false`,
                        {
                            headers: {
                                // set the auth token to the user's jwt
                                Authorization: `Bearer ${getToken()}`,
                            },
                        }
                    );
                    const sachverstaendigerUserData = await sachverstaendigerUser.json();
                    SachverstaendigerValuesOptions = sachverstaendigerUserData.reduce((acc, user) => {
                        acc[user.id] = {Name: user.username};
                        return acc;
                    }, {});
                }
                setInitialValuesState(getInitialValuesFormular(dataContent));
            }
        } catch (error) {
            message.error("Error while fetching Auftrag!");
            console.log(error);
        } finally {
            //When everything is loaded jump to anchor (if selected)
            let currentUrl = window.location.href.split("#");
            if(currentUrl[1] && jumptoanchor == true && document.querySelector('a[href="#'+currentUrl[1]+'"]')){ // Anchor(=Hashtag) is in URL
                document.querySelector('a[href="#'+currentUrl[1]+'"]').click();
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if(user) {
            if (saveIcon) {
                fetchAuftrag(false).then(); //No jump to anchor if an update of data triggers reload of data
            } else {
                fetchAuftrag().then();
            }
        }
    }, [updateTrigger]);

    useEffect(() => {
        if (saveIcon) {
            fetchAuftrag(false).then(); //No jump to anchor if an update of data triggers reload of data
        } else {
            fetchAuftrag().then();
        }
    }, [user]);


    const updateAuftrag = (
        putBody,
        forceReload = false,
        forcePageReload = false
    ) => {
        //setIsLoading(true);

        setSaveIcon(true);
        setSaveIcon1(true);
        fetch(`${getAPIurl()}/helper/acl/auftrag/${auftragID}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(putBody),
        })
            .then((response) => {

                if (!response.ok) {
                    throw new Error("Failed to update data.");
                }
                return response.json();
            })
            .then((responseData) => {
                // New component (undefined item.id) => reload;
                if (forceReload) {
                    if (forcePageReload === true) {
                         window.location.reload(); // This should be eliminated, just temporarly
                        setIsLoading(false);
                    } else {
                        setUpdateTrigger((prev) => !prev);
                        setIsLoading(false);
                    }
                }
                //setIsLoading(false);
                // Setze das Speicher-Icon nach X Millisekunden zurück
                setTimeout(() => {
                    setSaveIcon(false);
                }, 300);
                setTimeout(() => {
                    setSaveIcon1(false);
                }, 3000);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    /**
     * Generates a new card and adds it to the specified component.
     *
     * @param {string} component - The component to add the card to.
     * @param {boolean} [uploadForm=false] - Indicates whether an upload form should be created for the new card.
     */
    const addCard = (component, uploadForm = false) => {
        document.getElementById(`button_new_component_${component}`).hidden = true;

        if (uploadForm) {
            const updatedUploadFormsAdd = uploadForms;
            updatedUploadFormsAdd[component][undefined] = {
                file: "",
                visible: true,
            };
            setUploadForms(updatedUploadFormsAdd);
        }

        setCards({
            ...cards,
            [component]: [...cards[component], {}],
        });
    };
    /**
     * Deletes a card from the specified component at the given index with the provided ID.
     *
     * @param {string} component - The component from which the card will be deleted.
     * @param {number} index - The index of the card in the component.
     * @param {string} id - The ID of the card to be deleted.
     */
    const deleteCard = (component, index, id) => {
        Modal.confirm({
            title: "Komponente löschen",
            content: "Möchten Sie diesen Eintrag wirklich löschen?",
            onOk() {

                deleteCardConfirmed(component, index, id);
            },
            onCancel() {
                console.log("Löschung abgebrochen");
            },
        });
    };

    /**
     * Deletes a card from the specified component.
     *
     * @param {string} component - The name of the component.
     * @param {number} index - The index of the card to be deleted.
     * @param {string} id - The ID of the card to be deleted.
     */
    const deleteCardConfirmed = (component, index, id) => {
        const updatedCardsComponent = [...cards[component]];
        updatedCardsComponent.splice(index, 1); // Remove the card at the specified index
        cards[component] = updatedCardsComponent;
        setCards(cards);
        /* Eintrag wird bereits in dataContent gelöscht, Ursache unklar (vermutlich durch Veränderung des states setCards.
            Daher updateDataContent aktuell nicht erforderlich (aber egtl ein Bug, spätestens zur Zentralisierung wichtig):*/
        updateDataContent(component, index, dataContent, "deleteComponent");
        putBody.data = dataContent;
        updateAuftrag(putBody.data, true);
    };

    const customUploadFile = async (file) => {
        try {
            if (file) {
                const responseUF = await uploadFile(file, false, auftragID);

                // Check if the response is an array
                updateDataContentValue = {};

                if (Array.isArray(responseUF)) {
                    // Use forEach to iterate through the array (normally 1, because multiple files are not allowed)
                    responseUF.forEach((item, index) => {
                        updateDataContentValue["url"] = item.hash + item.ext;
                        updateDataContentValue["id"] = item.id;
                        updateDataContentValue["name"] = item.name;
                    });
                }

                // Save changes in database
                updateDataContentContainer = dataContent;
                updateDataContent(
                    currentFileChanged,
                    updateDataContentValue,
                    updateDataContentContainer,
                    "updateByIndex"
                ); // updateByIndex (because both uploads are single component)
                putBody.data = dataContent;
                updateAuftrag(putBody.data, true, false);
                const elements = document.getElementsByClassName("button_new_component");
                for (let i = 0; i < elements.length; i++) {
                    elements[i].hidden = false;
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleRemoveAttachment = (dataContentEntry, file) => {
        Modal.confirm({
            title: "Datei löschen",
            content: "Möchten Sie die Datei wirklich löschen?",
            onOk() {
                handleRemoveAttachmentConfirmed(dataContentEntry, file);
            },
            onCancel() {
                console.log("Löschung abgebrochen");
            },
        });
    };

    async function handleRemoveAttachmentConfirmed(dataContentEntry, file) {
        try {
            const response = await fetch(
                `${getAPIurl()}/helper/acl/upload/${file}`,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );

            if (response.ok) {
                const responseData = await response.json();
            } else {
                console.log(`Request failed with status ${response.status}`);
            }
        } catch (error) {
            return error;
        }

        updateDataContentContainer = dataContent;
        updateDataContent(
            dataContentEntry,
            "-",
            updateDataContentContainer,
            "updateByIndex"
        ); // updateByIndex (because both uploads are single component)
        putBody.data = dataContent;

        updateAuftrag(putBody.data, true, false);
    }

    const FileChanged = (name, id, component) => {
        currentFileChanged = name;
        currentFileChangedID = id;
        currentFileChangedComponent = component;
    };

    useEffect(() => formFormular.resetFields(), [initialValues]);

    return (
        <div className="pt-[40px]">
            {loading && <LoadingScreen />}
            {saveIcon1 && <SaveIcon saveIcon={saveIcon} />}
            <div className={`p-4 xl:ml-0  ml-[15px] mr-[15px] ${isSwitchOn ? 'bg-black' : 'bg-white'} ${isSwitchOn ? 'borderRadiuClass1' : ''} rounded-tr-20 mb-4 md:mb-[20px] borderRadiuClass max-w-[100%] md:pl-2`}>
                <div className={`generalText ml-[6px] text-13 mb-4 md:text-16 ${isSwitchOn ? 'text-white' : 'text-black'}`}>
                    <Linki to="/">Startseite</Linki>/<span className="text-19 font-bold"><Linki
                    to={`/auftrag/${location.pathname.split("/")[2]}`}>Auftrag</Linki></span>
                </div>
            </div>
            <div className={` ${isSwitchOn ? 'cardsGutachten1' : 'cardsGutachten'}`}>
                <Row
                    className="w-[full] pl-[15px] items-center pr-[15px] justify-center pt[20px] pb-[20px] flex-wrap border-b border-gray-300">
                    {dataContent && dataContent.gutachtens && dataContent.gutachtens.map((entry, index) => (
                        <Col className="w-[100%] lg:w-[33%] gap-[10px] flex flex-wrap justify-center" key={entry.id}> {/* Achten Sie auf den 'key'-Prop für die Iteration */}
                            <Card className="sm:w[50%] w-full max-w-[325px] m-auto customCard">
                                <Linki  onClick={() => {window.location.href="/gutachten/"+auftragID+"/"+entry.id}}>
                                    <h2 className={` ${isSwitchOn ? 'whiteNew' : 'text-white'}`}>Gutachten {index + 1} bearbeiten</h2>
                                </Linki>
                            </Card>
                        </Col>
                    ))}
                    <Col className="w-[100%] sm:w[50%] max-w-[325px] lg:w-[33%]">
                        <button style={{ cursor: 'pointer', border: 'none' }} className="w-full" onClick={addNewGutachten}><Card  >
                            <h2 className={` ${isSwitchOn ? 'whiteNew' : 'text-white'}`}><PlusOutlined /> Zusätzliches Gutachten anlegen</h2>
                        </Card></button>
                    </Col>
                </Row>
            </div>
            <hr className="d-none" />
            <div>
                {dataContent && enumerationValuesContent && ( // Bedingung überprüft, ob dataContent & enumerationValuesContent bereits vorhanden ist
                    <Form
                        form={formFormular}
                        onBlur={(e) => {
                            // Hier wird das onBlur-Ereignis für das gesamte Formular abgefangen
                            if (e.target.type !== "button") { //Not at addCard or empty Date
                                const updateByIDArray = ["Sachverstaendiger", "Kontaktliste", "Rechnungen", "Leistungen", "AN_Sum_Auslagen", "AN_MAHon_net", "Links"];
                                updateDataContentContainer = dataContent;
                                let updateDataContentValue;
                                if (e.target.type === "search") {
                                    updateDataContentValue = selectedOption;
                                } else {
                                    updateDataContentValue = e.target.value;
                                }
                                if (e.target.placeholder === "Datum auswählen" && e.target.value == "") { //In case empty date
                                    updateDataContentValue = null;
                                }
                                if (updateByIDArray.some(value => e.target.id.includes(value))) {
                                    updateDataContent(e.target.id, updateDataContentValue, updateDataContentContainer, "updateByID");
                                } else {
                                    updateDataContent(e.target.id, updateDataContentValue, updateDataContentContainer, "updateByIndex");
                                }
                                putBody.data = dataContent;
                                if (e.target.id.includes("undefined")) {
                                    setIsLoading(true);
                                    updateAuftrag(putBody.data, true, false);//ForceReload
                                    const elements = document.getElementsByClassName("button_new_component");
                                    for (let i = 0; i < elements.length; i++) {
                                        elements[i].hidden = false;
                                    }
                                } else {
                                    updateAuftrag(putBody.data, false);
                                }
                            }
                        }}
                        initialValues={initialValues}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        layout="horizontal"
                        // style={{ maxWidth: 600 }}
                    >
                        <div id="allgemein" className="scrollable-section flex flex-col p-[20px]">
                            <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Allgemein</h3>
                            <div className="flex flex-col pl-[3%] md:pl-[10%]">
                                <Form.Item label="Auftragsnummer" name='Auftragsnummer'
                                           className={`max-content ${isSwitchOn ? 'maxColor' : ''} `}>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Kundennummer" name='Kundennummer'
                                           className={`max-content ${isSwitchOn ? 'maxColor' : ''} `}>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Kunden-Aktenzeichen" name='KundenAktenzeichen'
                                           className={`max-content ${isSwitchOn ? 'maxColor' : ''} `}>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Jahr" name='Jahr'
                                           className={`max-content ${isSwitchOn ? 'maxColor' : ''} `}>
                                    <InputNumber className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Auftragsart" name="AuftragsartV"
                                           className={`max-content ${isSwitchOn ? 'maxColor' : ''} `}>
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent.Auftragsart?.map((option, index) => ({
                                            value: option,
                                            key: index
                                        }))}
                                        filterOption={(inputValue, option) =>
                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Auftragsart"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                                <Form.Item label="Art der Beauftragung" name="BeauftragungsartV"
                                           className={`max-content ${isSwitchOn ? 'maxColor' : ''} `}>
                                    <Select onChange={handleSelectChange}>
                                        {enumerationValuesContent.Beauftragungsart.map((option, index) => (
                                            <Option key={index} value={option}>
                                                {option}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Status Auftrag" name="Auftrag_StatusV"
                                           className={`max-content ${isSwitchOn ? 'maxColor' : ''} `}>
                                    <Select onChange={handleSelectChange}>
                                        {enumerationValuesContent.Auftrag_Status.map((option, index) => (
                                            <Option key={index} value={option}>
                                                {option}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>


                            </div>
                        </div>
                        {user && !user.extern && (
                        <div id="sachverstaendiger" className="scrollable-section border-t border-gray-300">
                            <div
                                className="flex flex-col sm:flex-row justify-between sm:items-center p-[10px]  md:p-[20px] pr-[3%] md:pr-[10%]">
                                <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Sachverständiger</h3>

                            </div>
                            {cards.Sachverstaendiger && SachverstaendigerValuesOptions && Array.isArray(cards.Sachverstaendiger) && cards.Sachverstaendiger?.map((entry, index) => {;
                                const filteredCard = cards.Sachverstaendiger.filter(card => card.id === entry.id);
                                let fullName;
                                if(filteredCard.length > 0 && filteredCard[0].SachverstaendigerID && SachverstaendigerValuesOptions[filteredCard[0].SachverstaendigerID]) {
                                    fullName = SachverstaendigerValuesOptions[filteredCard[0].SachverstaendigerID].Name;
                                } else {
                                    fullName = "";
                                }
                                const words = fullName?.split(' ');
                                const initials = words?.length >= 2 ? `${words[0][0]}${words[1][0]}` : '';
                                return (
                                    <Card className={`pl-[3%] ${isSwitchOn ? 'bgdark' : 'bgwhite'}`} size="small"
                                          key={index}>
                                        <div className="sachCenter">
                                            <div className="sachCenterMain">
                                                {selectedSachverstaendigerIDs[entry.id] && (<>
                                                        <div className="flex items-center">
                                                            {initials && <div
                                                                className={`neralfamily w-[80px] text-[20px] sm:text-[36px] ${isSwitchOn ? 'text-white' : 'text-black'} font-bold`}>
                                                                {initials}
                                                            </div>}
                                                            <div
                                                                className={`sm:ml-[40px] ml-[20px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>
                                                                <div
                                                                    className=" generalfamily text-[20px] text-[#FF2E3B] ">{fullName}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div
                                                className={`flex justify-center sachCenterInner  sacherCenter ${isSwitchOn ? 'maxColor' : ''}`}>
                                                <Form.Item label={``}
                                                           name={`Sachverstaendiger__${entry.id}__SachverstaendigerID`}>
                                                    <Select className="selectInput"
                                                            onChange={(value) => handleSelectChange(value, entry.id, "sachverstaendiger")}
                                                            value={selectedSachverstaendigerIDs[entry.id]}>
                                                        {Object.keys(SachverstaendigerValuesOptions)?.map((id) => (
                                                            <Option key={id} value={id}>
                                                                {id}: {SachverstaendigerValuesOptions[id]?.Name}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <div className="ml-[10px] gap-[10px] flex">
                                                    {entry.id != undefined && (
                                                        <div className={`w-full flex`}>
                                                            <div style={{cursor: 'pointer'}}
                                                                 onClick={() => deleteCard("Sachverstaendiger", index, entry.id)}>
                                                                <RiDeleteBin5Fill className="text-[#FF2E3B] w-[18px] h-[20px]"/>
                                                            </div>
                                                        </div>
                                                )}
                                                </div>
                                            </div>
                                        </div>

                                    </Card>
                                );
                            })}
                            <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Button
                                    className={` ${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  border-none m-[20px] button_new_component ml-auto sm:ml-[0] text-[12px] md:text-[14px]  rounded-[30px] border-none1`}
                                    onClick={() => addCard("Sachverstaendiger", false)}
                                    id={"button_new_component_Sachverstaendiger"}>Sachverständiger dem Auftrag hinzufügen</Button>

                            </div>
                        </div>
                        )}
                        {user && !user.extern && (
                        <div id="kontaktliste" className="scrollable-section border-t border-gray-300">
                            <div
                                className="flex justify-between items-center p-[10px]  md:p-[20px] pr-[3%] md:pr-[10%]">
                                <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Kontaktliste</h3>

                            </div>
                            {cards.Kontaktliste && kontakteValues && selectedKontaktIDs && kontakteValuesOptions && Array.isArray(cards.Kontaktliste) && cards.Kontaktliste?.map((entry, index) => {
                                // Calculate initials from Kontaktname
                                const fullName = kontakteValues[selectedKontaktIDs[entry.id]]?.Kontaktname;
                                const words = fullName?.split(' ');
                                const initials = words?.length >= 2 ? `${words[0][0]}${words[1][0]}` : '';

                                return (
                                    <Card className={`pl-[3%]  ${isSwitchOn ? 'bgdark' : 'bgwhite'}`} size="small"
                                          key={index}>
                                        <div className="sachCenter">
                                            <div className="sachCenterMain">
                                                {selectedKontaktIDs[entry.id] && (
                                                    <>
                                                        <div className="flex items-center">
                                                            {initials && <div
                                                                className={`neralfamily w-[80px]  text-[20px] sm:text-[36px] ${isSwitchOn ? 'text-white' : 'text-black'} font-bold`}>
                                                                {initials}
                                                            </div>}
                                                            <div
                                                                className={`sm:ml-[40px] ml-[20px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>
                                                                <div
                                                                    className=" generalfamily text-[20px] text-[#FF2E3B]">{kontakteValues[selectedKontaktIDs[entry.id]].Kontaktname}</div>
                                                                <div
                                                                    className={`after:generalfamily text-[15px]  ${isSwitchOn ? 'text-white' : 'text-[#4E4E4E]'}`}>{kontakteValues[selectedKontaktIDs[entry.id]].KontakttypV}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                                <div
                                                    className={`flex justify-center sachCenterInner  sacherCenter ${isSwitchOn ? 'maxColor' : ''}`}>
                                                    <Form.Item label={``} name={`Kontaktliste__${entry.id}__kontaktID`}>
                                                        <Select className="selectInput"
                                                                onChange={(value) => handleSelectChange(value, entry.id, "kontakt")}
                                                                value={selectedKontaktIDs[entry.id]}>
                                                            {Object.keys(kontakteValuesOptions)?.map((id) => (
                                                                <Option key={id} value={id}>
                                                                    {id}: {kontakteValuesOptions[id]?.Kontaktname}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <div className="ml-[10px] gap-[10px] flex">
                                                        {entry.id != undefined && (
                                                            <div className={`w-full flex`}>
                                                                <Linki style={{cursor: 'pointer'}}
                                                                       to={`/kontakte/${selectedKontaktIDs[entry.id]}/${auftragID}`}>
                                                                    <EditOutlined
                                                                        className={` ${isSwitchOn ? 'text-[#FF2E3B]' : 'text-black'}`}/>
                                                                </Linki>
                                                                <div style={{cursor: 'pointer'}}
                                                                     onClick={() => deleteCard("Kontaktliste", index, entry.id)}>
                                                                    <RiDeleteBin5Fill className="text-[#FF2E3B] w-[19px] h-[21px]"/>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                        </div>
                                    </Card>
                                );
                            })}

                            <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Button
                                    className={` ${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  border-none m-[20px] button_new_component ml-auto sm:ml-[0] text-[12px] md:text-[14px]  rounded-[30px] border-none1`}
                                    onClick={() => addCard("Kontaktliste", false)}
                                    id={"button_new_component_Kontaktliste"}>Kontakt dem Auftrag hinzufügen</Button>
                                <Button
                                    className={` ${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  border-none m-[20px] button_new_component ml-auto sm:ml-[0] text-[12px] md:text-[14px]  rounded-[30px] border-none1`}
                                    onClick={() => handleCreateKontakt(navigate,auftragID)}
                                >Neuen Kontakt erstellen</Button>
                            </div>
                        </div>
                        )}
                        <div id="angebot" className="scrollable-section ">
                            <div className="border-t border-gray-300 p-[20px]">
                                <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Angebot</h3>
                                <div className="flex flex-col pl-[3%] md:pl-[10%]">
                                    <Form.Item label="Datum Angebot"
                                               className={`max-content1 ${isSwitchOn ? 'maxColor' : ''} `}>
                                        <Form.Item name={`Angebot__Datum_Angebot`}>
                                            <DatePicker format={"DD.MM.YYYY"} className="inputsAll"/>
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item label="Datum Auftrag"
                                               className={`max-content1 ${isSwitchOn ? 'maxColor' : ''} `}>
                                        <Form.Item name={`Angebot__Datum_Auftrag`}>
                                            <DatePicker format={"DD.MM.YYYY"} className="inputsAll"/>
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item label="Datum vereinbarte Fertigstellung"
                                               className={`max-content1 ${isSwitchOn ? 'maxColor' : ''} `}>
                                        <Form.Item name={`Angebot__Datum_vereinbarteFertigstellung`}>
                                            <DatePicker format={"DD.MM.YYYY"} className="inputsAll"/>
                                        </Form.Item>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="border-t border-gray-300">
                                <div
                                    className="flex justify-between items-center p-[10px]  md:p-[20px] pr-[3%] md:pr-[10%]">
                                    <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Summe
                                        Auslagen</h3>

                                </div>
                                {cards && Array.isArray(cards.AN_Sum_Auslagen) && cards.AN_Sum_Auslagen.map((entry, index) => {
                                    return (
                                        <Card className={`pl-[3%] md:pl-[10%] ${isSwitchOn ? 'bgdark' : 'bgwhite'}`}
                                              size="small" key={index}>
                                            <div
                                                className={`flex justify-center  sacherCenterTextnew ${isSwitchOn ? 'maxColor' : ''}`}>
                                                <Form.Item label="Betrag Auslage netto (in €)"
                                                           name={`AN_Sum_Auslagen__${entry.id}__Betrag_Auslage_netto`}>
                                                    <MyInputNumber className="inputsAll"/>
                                                </Form.Item>
                                                <div className="ml-[10px] flex">
                                                    <div style={{cursor: 'pointer'}}
                                                         onClick={() => deleteCard("AN_Sum_Auslagen", index, entry.id)}>
                                                        <RiDeleteBin5Fill className="text-[#FF2E3B] w-[18px] h-[20px]"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                                <Form.Item label="Auslagen Art"
                                                           name={`AN_Sum_Auslagen__${entry.id}__Auslagen_Art`}>
                                                    <Input className="inputsAll"/>
                                                </Form.Item>
                                            </div>
                                        </Card>
                                    );
                                })}

                                <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Button
                                        className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  text-[12px] md:text-[14px] m-[20px] button_new_component rounded-[30px] border-none`}
                                        onClick={() => addCard("AN_Sum_Auslagen", false)}
                                        id={"button_new_component_AN_Sum_Auslagen"}>Auslage hinzufügen</Button>
                                </div>
                            </div>
                            <div className="border-t border-gray-300">
                                <div
                                    className="flex flex-col sm:flex-row justify-between sm:items-center p-[10px]  md:p-[20px] pr-[3%] md:pr-[10%]">
                                    <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Mitarbeiterhonorar</h3>

                                </div>

                                {cards && Array.isArray(cards.AN_MAHon_net) && cards.AN_MAHon_net.map((entry, index) => (
                                    <Card className={`pl-[3%] md:pl-[10%] ${isSwitchOn ? 'bgdark' : 'bgwhite'}`}
                                          size="small" key={index}>
                                        <div
                                            className={`flex justify-center  sacherCenterText ${isSwitchOn ? 'maxColor' : ''}`}>
                                            <Form.Item label="Beschreibung"
                                                       name={`AN_MAHon_net__${entry.id}__Beschreibung`}>
                                                <TextArea className="textBorder" autoSize={true}/>
                                            </Form.Item>
                                            <div className="ml-[10px] gap-[10px] flex">
                                                <div style={{cursor: 'pointer'}}
                                                     onClick={() => deleteCard("AN_MAHon_net", index, entry.id)}>
                                                    <RiDeleteBin5Fill className="text-[#FF2E3B] w-[18px] h-[20px]"/>
                                                </div>
                                            </div>

                                        </div>

                                        <div
                                            className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                            <Form.Item label="Mitarbeiterhonorar Betrag netto (in €)"
                                                       name={`AN_MAHon_net__${entry.id}__MAHon_Betrag_einzeln_net`}>
                                                <MyInputNumber className="inputsAll"/>
                                            </Form.Item>
                                        </div>
                                        <div
                                            className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                            <Form.Item label="Name Mitarbeiter"
                                                       name={`AN_MAHon_net__${entry.id}__Name_MA`}>
                                                <Input className="inputsAll"/>
                                            </Form.Item>
                                        </div>
                                    </Card>
                                ))}
                                <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Button
                                        className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  text-[10px] ml-auto sm:ml-0 md:text-[14px] m-[20px] button_new_component rounded-[30px] border-none`}
                                        onClick={() => addCard("AN_MAHon_net", false)}
                                        id={"button_new_component_AN_MAHon_net"}>Mitarbeiterhonorar hinzufügen</Button>
                                </div>
                            </div>

                        </div>
                        <div id="rechnungen" className="scrollable-section border-t border-gray-300">
                            <div className="flex justify-between items-center p-[20px] pr-[10%]">
                                <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Rechnungen</h3>

                            </div>

                            {cards.Rechnungen && Array.isArray(cards.Rechnungen) && cards.Rechnungen.map((entry, index) => (
                                <Card className={`pl-[3%] md:pl-[10%] ${isSwitchOn ? 'bgdark' : 'bgwhite'}`}
                                      size="small" key={index}>
                                    <div
                                        className={`flex  justify-center sacherCenterTextnew1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label="Name Rechnungsaussteller"
                                                   name={`Rechnungen__${entry.id}__Name_Rechnungsaussteller`}>
                                            <Input className="inputsAll"/>
                                        </Form.Item>
                                        <div style={{marginLeft: "10px", cursor: 'pointer'}}
                                             onClick={() => deleteCard("Rechnungen", index, entry.id)}>
                                            <RiDeleteBin5Fill className="text-[#FF2E3B] w-[18px] h-[20px]"/>
                                        </div>
                                    </div>

                                    <div
                                        className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label="Rechnungsart" name={`Rechnungen__${entry.id}__Rechnung_ARTV`}>
                                            <AutoComplete
                                                className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                                options={enumerationValuesContent.com_Rechnung_ART?.map((option, index) => ({
                                                    value: option,
                                                    key: index
                                                }))}
                                                filterOption={(inputValue, option) =>
                                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={handleSelectChange} // Add your change handler if needed
                                            >
                                                <TextArea
                                                    placeholder="Rechnungsart"
                                                    autoSize={true}
                                                    className="textInput"
                                                />
                                            </AutoComplete>

                                        </Form.Item>
                                    </div>
                                    <div
                                        className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label="Rechnungsbetrag netto (in €)"
                                                   name={`Rechnungen__${entry.id}__Rechnungsbetrag_net`}>
                                            <MyInputNumber className="inputsAll"/>
                                        </Form.Item>
                                    </div>
                                </Card>
                            ))}
                            <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Button
                                    className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  text-[10px] ml-auto sm:ml-0 md:text-[14px] m-[20px] button_new_component rounded-[30px] border-none`}
                                    onClick={() => addCard("Rechnungen", false)} id={"button_new_component_Rechnungen"}>Rechnung
                                    hinzufügen</Button>
                            </div>
                        </div>
                        <div id="leistungen" className="scrollable-section border-t border-gray-300">
                            <div className="flex justify-between items-center p-[20px] pr-[10%]">
                                <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Leistungen</h3>

                            </div>
                            {cards.Leistungen && Array.isArray(cards.Leistungen) && cards.Leistungen.map((entry, index) => (
                                <Card className={`pl-[3%] md:pl-[10%] ${isSwitchOn ? 'bgdark' : 'bgwhite'}`}
                                      size="small" key={index}>
                                    <div
                                        className={`flex  justify-center sacherCenterTextnew ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label="Tätigkeit" name={`Leistungen__${entry.id}__Taetigkeit`}>
                                            <Select onChange={handleSelectChange} className="selectInput">
                                                {enumerationValuesContent.com_Taetigkeit.map((option, index) => (
                                                    <Option key={index} value={option}>
                                                        {option}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <div style={{marginLeft: "10px", cursor: 'pointer'}}
                                             onClick={() => deleteCard("Leistungen", index, entry.id)}>
                                            <RiDeleteBin5Fill className="text-[#FF2E3B] w-[18px] h-[20px]"/>
                                        </div>
                                    </div>

                                    <div
                                        className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label="Dauer in Stunden" name={`Leistungen__${entry.id}__DauerInH`}>
                                            <MyInputNumber className="inputsAll"/>
                                        </Form.Item>
                                    </div>
                                    <div
                                        className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label="Name Mitarbeiter" name={`Leistungen__${entry.id}__Name_MA`}>
                                            <Input className="inputsAll"/>
                                        </Form.Item>
                                    </div>
                                </Card>
                            ))}
                            <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Button
                                    className={` ${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  rounded-[30px] border-none m-[20px] button_new_component`}
                                    onClick={() => addCard("Leistungen", false)} id={"button_new_component_Leistungen"}>Leistung
                                    hinzufügen</Button>
                            </div>
                        </div>
                        <div id="un-auftrag" className="scrollable-section border-gray-300 p-[20px] border-t ">
                            <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Auftragsunterlagen</h3>
                            <div className="flex flex-col pl-[3%] md:pl-[10%]">


                                <Form.Item label="Auftrag versendet am"
                                           className={`max-content1 ${isSwitchOn ? 'maxColor' : ''} `}>
                                    <Form.Item name={`UN_Auftrag__Auftrag_vd`}>
                                        <DatePicker format={"DD.MM.YYYY"} className="inputsAll1"/>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label="Auftrag eingegangen am"
                                           className={`max-content1 ${isSwitchOn ? 'maxColor' : ''} `}>
                                    <Form.Item name={`UN_Auftrag__Auftrag_ed`}>
                                        <DatePicker format={"DD.MM.YYYY"} className="inputsAll1"/>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label="Auftrag" name={`UN_Auftrag__Auftrag_Doc`}
                                           className={`max-content2 ${isSwitchOn ? 'maxColor' : ''} `}>
                                    {uploadForms["Auftrag_Doc"] && uploadForms["Auftrag_Doc"] && uploadForms["Auftrag_Doc"].visible ? (
                                        <Upload.Dragger customRequest={customUploadFile}
                                                        onChange={(info) => FileChanged(`UN_Auftrag__Auftrag_Doc`, 0, "Auftrag_Doc")}
                                                        accept="image/jpg,image/jpeg,image/png,.doc,.docx,.xls,.xlsx,.pdf">
                                            <p className="ant-upload-drag-icon">
                                                <UploadOutlined className="text-red"/>
                                            </p>
                                            <p className="ant-upload-text">Klicke oder ziehe Dateien hierhin, um sie
                                                hochzuladen</p>
                                            <p className="ant-upload-hint">Unterstützte Dateiformate: .jpg, .png, .pdf,
                                                .doc(x), .xls(x)</p>
                                        </Upload.Dragger>
                                    ) : uploadForms["Auftrag_Doc"] !== undefined ? (
                                        <>
                                            <div>
                                                <Button icon={<ExportOutlined/>}
                                                        onClick={() => openS3File(uploadForms["Auftrag_Doc"].file.url)}>{uploadForms["Auftrag_Doc"].file.name}</Button>
                                                <Button icon={<DeleteOutlined/>}
                                                        onClick={() => handleRemoveAttachment(`UN_Auftrag__Auftrag_Doc`, uploadForms["Auftrag_Doc"].file.id)}>
                                                    Löschen
                                                </Button>
                                            </div>
                                        </>
                                    ) : null}
                                </Form.Item>
                                <Form.Item label={"Vollmacht"}
                                           className={`max-content1 ${isSwitchOn ? 'maxColor' : ''} `}>
                                    <Button className="inputsAll1 mb-[12px]"
                                            icon={<DownloadOutlined className="text-[#FF2E3B]"/>}
                                            onClick={() => downloadRenderFile("Vollmacht", "docx", auftragID, null)}
                                            style={{cursor: 'pointer'}}>Vorlage herunterladen</Button>
                                </Form.Item>
                                <Form.Item label="Vollmacht versendet am"
                                           className={`max-content1 ${isSwitchOn ? 'maxColor' : ''} `}>
                                    <Form.Item name={`UN_Auftrag__Vollmacht_vd`}>
                                        <DatePicker format={"DD.MM.YYYY"} className="inputsAll1"/>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label="Vollmacht eingegangen am"
                                           className={`max-content1 ${isSwitchOn ? 'maxColor' : ''} `}>
                                    <Form.Item name={`UN_Auftrag__Vollmacht_ed`}>
                                        <DatePicker format={"DD.MM.YYYY"} className="inputsAll1"/>
                                    </Form.Item>
                                </Form.Item>

                                <Form.Item label="Vollmacht" name={`UN_Auftrag__Vollmacht_Doc`}
                                           className="max-content2">
                                    {uploadForms["Vollmacht_Doc"] && uploadForms["Vollmacht_Doc"] && uploadForms["Vollmacht_Doc"].visible ? (
                                        <>
                                            <Upload.Dragger customRequest={customUploadFile}
                                                            onChange={(info) => FileChanged(`UN_Auftrag__Vollmacht_Doc`, 0, "Vollmacht_Doc")}
                                                            accept="image/jpg,image/jpeg,image/png,.doc,.docx,.xls,.xlsx,.pdf">
                                                <p className="ant-upload-drag-icon">
                                                    <UploadOutlined/>
                                                </p>
                                                <p className="ant-upload-text">Klicke oder ziehe Dateien hierhin, um sie
                                                    hochzuladen</p>
                                                <p className="ant-upload-hint">Unterstützte Dateiformate: .jpg, .png,
                                                    .pdf, .doc(x), .xls(x)</p>
                                            </Upload.Dragger>
                                        </>
                                    ) : uploadForms["Vollmacht_Doc"] !== undefined ? (
                                        <>
                                            <div>
                                                <Button icon={<ExportOutlined/>}
                                                        onClick={() => openS3File(uploadForms["Vollmacht_Doc"].file.url)}>{uploadForms["Vollmacht_Doc"].file.name}</Button>
                                                <Button icon={<DeleteOutlined/>}
                                                        onClick={() => handleRemoveAttachment(`UN_Auftrag__Vollmacht_Doc`, uploadForms["Vollmacht_Doc"].file.id)}>
                                                    Löschen
                                                </Button>
                                            </div>
                                        </>
                                    ) : null}
                                </Form.Item>
                                <Form.Item label="Anschreiben"
                                           className={`max-content1 ${isSwitchOn ? 'maxColor' : ''} `}>
                                    <Button className="inputsAll1" icon={<DownloadOutlined className="text-[#FF2E3B]"/>}
                                            onClick={() => downloadRenderFile("Anschreiben", "docx", auftragID, null)}
                                            style={{cursor: 'pointer'}}>Vorlage herunterladen</Button>
                                </Form.Item>
                            </div>
                        </div>
                        <div id="links" className="scrollable-section border-b mt-[20px] border-gray-300 p-[20px]">
                            <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Linksammlung
                                </h3>
                            {cards.Links?.map((entry, index) => (
                                <Card className={`${isSwitchOn ? 'bgdark' : 'bgwhite'}`}
                                      size="small" key={index}>
                                    <div
                                        className={`flex justify-center  sacherCenterText ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label={`Kategorie:`}
                                                   name={`Links__${entry.id}__LinkkategorieV`}>
                                            <AutoComplete
                                                className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                                options={enumerationValuesContent?.sub_com_Linkkategorie?.map(
                                                    (option, index) => ({value: option, key: index})
                                                )}
                                                filterOption={(inputValue, option) =>
                                                    option.value
                                                        .toUpperCase()
                                                        .indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={handleSelectChange} // Add your change handler if needed
                                            >
                                                <TextArea
                                                    placeholder="Kategorie"
                                                    autoSize={true}
                                                    className="textInput"
                                                />
                                            </AutoComplete>
                                        </Form.Item>
                                        <div className="ml-[10px] gap-[10px] flex">
                                            <div style={{float: 'right', cursor: 'pointer'}}
                                                 onClick={() => deleteCard("Links", index, entry.id)}>
                                                <RiDeleteBin5Fill
                                                    className="text-[#FF2E3B] w-[18px] h-[20px] mt-[40px] sm:mt-0"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`flex justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label={`Beschreibung: `}
                                                   name={`Links__${entry.id}__Beschreibung`}>
                                            <Input className="inputsAll"/>
                                        </Form.Item>
                                    </div>
                                    <div
                                        className={`flex justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label={`Link: `}
                                                   name={`Links__${entry.id}__URL`}>
                                            <Input className="inputsAll"/>
                                        </Form.Item>
                                    </div>
                                </Card>
                            ))}
                            <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Button
                                    className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'} text-[10px] m-[20px] md:text-[14px] button_new_component rounded-[30px] border-none`}
                                    onClick={() => addCard("Links", false)} id={"button_new_component_Links"}>Link
                                    hinzufügen</Button>
                            </div>
                        </div>
                    </Form>
                )}
            </div>
        </div>
    );
};

export default AuftragDetails;
